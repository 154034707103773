<template>
  <div class="elv-accounts-counterparty-contact-named-list">
    <div class="elv-accounts-counterparty-contact-named-list-table">
      <el-table
        ref="tableListRef"
        v-table-skeleton="{ loading: props.tableLoading }"
        :data="props.tableData.list"
        border
        stripe
        row-key="counterpartyId"
        class="elv-contact-table"
        height="100%"
        header-cell-class-name="elv-contact-table-header__cell"
        header-row-class-name="elv-contact-table-header"
        row-class-name="elv-contact-table-row"
        cell-class-name="elv-contact-table-row__cell"
        @row-click="onCellClicked"
        @selection-change="onSelectionChange"
      >
        <template #empty>
          <div class="elv-contact-table-empty">
            <img src="@/assets/img/reports/table-built.png" alt="empty" />
            <p>
              No named counterparties yet.
              <span @click="emit('onAddCounterparty')">Create</span>&nbsp;<b>or</b>&nbsp;
              <router-link :to="{ name: 'entity-accounts-contacts-unnamed' }"
                >view all unnamed counterparties.</router-link
              >
            </p>
          </div>
        </template>
        <el-table-column type="selection" width="40" :disabled="true" reserve-selection :selectable="selectable" />
        <el-table-column :label="t('title.counterpartyName')" min-width="320">
          <template #default="{ row }">
            <div class="elv-contact-table-row__cell-text" :class="{ 'is-empty': !row?.name }">
              <SvgIcon
                v-if="row.type !== 'FEE'"
                :name="contactTypeData(row.type).value?.icon"
                width="16"
                :fill="contactTypeData(row.type).value?.fill"
                height="16"
              />
              <SvgIcon v-else :name="platformContactIcon(row?.platform?.type).value" width="16" height="16" />
              {{ row.type !== 'FEE' ? row?.name : row?.platform?.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.tag')" min-width="400">
          <template #default="{ row }">
            <div v-if="row?.tags?.length" class="elv-contact-table-row__cell-tags">
              <div v-for="item in row?.tags" :key="item.counterpartyTagId" class="elv-contact-table-row__cell-tag">
                {{ item.name }}
              </div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.memo')" width="254">
          <template #default="{ row }">
            <TextCell :params="{ data: row, value: { cellName: 'Memo' } }" />
          </template>
        </el-table-column>
        <el-table-column label="" width="116" class-name="elv-contact-table-row__cell-operation">
          <template #default="{ row }">
            <template v-if="row.type !== 'FEE'">
              <SvgIcon
                name="sources-edit"
                width="18"
                height="18"
                class="elv-contact-table-row__cell-operation-button"
                @click="onEditCounterparty(row)"
              />
              <SvgIcon
                name="sources-delete"
                width="18"
                height="18"
                class="elv-contact-table-row__cell-operation-button"
                @click="onDeleteCounterparty(row)"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.contactsParams.limit"
      :current-page="props.contactsParams.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
  <CounterPartyDetailDrawer ref="counterPartyOverlayRef" :drawerData="overlayDrawerData" @resetList="resetList" />

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :showCancelButton="true"
    :confirmButtonText="t('button.delete')"
    :cancelButtonText="t('button.cancel')"
    :title="t('button.deleteCounterparty')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteCounterparty"
    @onCancelEvent="onCancelDeleteCounterparty"
  >
    <template #content>
      <span class="elv-confirm-counterparty">{{
        isBatch
          ? t('message.batchDeleteCounterpartyInfo', {
              total: multipleSelection.length
            })
          : t('message.deleteCounterpartyInfo', {
              name: overlayDrawerData?.name
            })
      }}</span></template
    >
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Toolbar from './Toolbar.vue'
import { ElMessage } from 'element-plus'
import { find, isNumber } from 'lodash-es'
import AccountsApi from '@/api/AccountsApi'
import { notification } from 'ant-design-vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import TextCell from '@/components/Reports/Cell/TextCell.vue'
import { counterpartyTypeOptions } from '@/config/reports/index'
import CounterPartyDetailDrawer from '../../../components/Counterparty/DetailDrawer.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  contactsParams: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const emit = defineEmits(['resetList', 'onChangePage', 'onChangePageSize', 'onEditCounterparty', 'onAddCounterparty'])

const tableListRef = useTemplateRef('tableListRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const counterPartyOverlayRef = useTemplateRef('counterPartyOverlayRef')
const multipleSelection: any = ref([])
const overlayDrawerData: any = ref({})
const deleteLoading = ref(false)
const isImportCounterparty = ref(false)
const isBatch = ref(false)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const platformContactIcon: any = useComputedHook((type: string) => {
  let icon = ''
  switch (type) {
    case 'CHAIN':
      icon = 'blockchain'
      break
    case 'BANK':
      icon = 'temple'
      break
    case 'EXCHANGE':
      icon = 'opposite-directions'
      break
    case 'CUSTODY':
      icon = 'wallet'
      break
    case 'PAYMENT':
      icon = 'credit-card'
      break
    default:
      break
  }
  return icon
})

const contactTypeData: any = useComputedHook((type: string) => {
  return find(counterpartyTypeOptions, { value: type })
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const resetList = () => {
  emit('resetList')
}

const selectable = (row: any) => {
  return row.type !== 'FEE'
}

const onEditCounterparty = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  emit('onEditCounterparty', row)
}

const onCancelDeleteCounterparty = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onDeleteCounterparty = (row: any) => {
  overlayDrawerData.value = row
  isBatch.value = false
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onResetList = () => {
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
  emit('resetList')
}

const onBatchDeleteCounterparty = async () => {
  isBatch.value = true
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onClearSelected = () => {
  notification.close('elv-counterparty-table-toolbar')
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
}

const onSelectionChange = (val: any[]) => {
  multipleSelection.value = val
  if (val.length) {
    notification.open({
      key: 'elv-counterparty-table-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          selectedTotal: multipleSelection.value?.length,
          deleteLoading: deleteLoading.value,
          multipleSelection: multipleSelection.value,
          onClearSelected,
          onBatchDeleteCounterparty
        }),
      class: 'elv-counterparty-table-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-counterparty-table-toolbar')
  }
}

const onConfirmDeleteCounterparty = async () => {
  try {
    deleteLoading.value = true
    if (isBatch.value) {
      await AccountsApi.deleteCounterpartyBatch(entityId.value, {
        counterpartyIds: multipleSelection.value.map((item: any) => item.counterpartyId)
      })
    } else {
      await AccountsApi.deleteCounterparty(entityId.value, overlayDrawerData.value?.counterpartyId ?? '')
    }
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    if (isBatch.value) {
      notification.close('elv-counterparty-table-toolbar')
      onResetList()
    } else {
      emit('resetList')
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    overlayDrawerData.value = row
    if (
      e.target?.className !== 'elv-financials-cell-address-main__copy' &&
      e.target?.className !== 'elv-financials-cell-address-main__address' &&
      e.target?.className !== 'elv-contact-table-row__cell-operation' &&
      e.target?.className !== 'elv-contact-table-row__cell-operation-button' &&
      e.target?.href?.animVal !== '#icon-sources-edit' &&
      !isNumber(e.target?.className?.animVal?.indexOf('elv-contact-table-row__cell-operation-button')) &&
      e.target?.href?.animVal !== '#icon-sources-delete'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      counterPartyOverlayRef.value?.onCheckDrawerStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

watch(
  () => entityStore.entityDetail,
  () => {
    if (
      entityStore.entityDetail?.entityId === entityId.value &&
      entityStore.entityDetail?.progress?.counterpartyCsvUploadList?.length &&
      route?.name === 'entity-accounts-contacts-named'
    ) {
      const importStatus = entityStore.entityDetail?.progress?.counterpartyCsvUploadList.some((item: any) => {
        if (item.status === 'WAITING') return true
        return false
      })
      if (isImportCounterparty.value && !importStatus) {
        emit('resetList')
      }
      isImportCounterparty.value = importStatus
    }
  },
  { immediate: true, deep: true }
)

defineExpose({
  ref: tableListRef
})

onBeforeUnmount(() => {
  notification.destroy()
  multipleSelection.value = []
  isImportCounterparty.value = false
  tableListRef.value?.clearSelection()
})
</script>

<style lang="scss">
.elv-contact-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-contact-table-header {
    background: #eef4fb;

    .elv-contact-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.elv-contact-table-row__transaction .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-contact-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    &.elv-contact-table-row__transaction .elv-table-number {
      justify-content: flex-end;
      color: #0e0f11;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;

      .elv-contact-table-row__cell-text {
        display: flex;
        align-items: center;
        font-weight: 500;

        svg {
          margin-right: 6px;
        }

        &.is-empty {
          color: #aaafb6;
        }
      }

      .elv-contact-table-row__cell-tags {
        display: flex;
      }

      .elv-contact-table-row__cell-tag {
        display: flex;
        height: 24px;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 40px;
        border: 1px solid #d0d4d9;
        color: #636b75;
        leading-trim: both;
        text-edge: cap;
        font-size: 11px;
        font-weight: 500;
        line-height: normal;
        margin-right: 4px;
        white-space: nowrap;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    &.elv-contact-table-row__cell-operation {
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
          }

          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1e2024;
          }
        }
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-contact-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-contact-table-row__transaction {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }

  .el-table__empty-block {
    transform: translateY(-20%);

    .elv-contact-table-empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
        box-sizing: border-box;
        margin-bottom: 13px;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
          margin-left: 8px;
          color: #2f63eb;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          text-decoration-line: underline;
        }

        b {
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        a {
          color: #2f63eb;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.elv-confirm-counterparty {
  color: #636b75;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
<style lang="scss" scoped>
.elv-accounts-counterparty-contact-named-list {
  flex: 1;
  min-height: 0px;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-accounts-counterparty-contact-named-list-table {
    width: 100%;
    height: calc(100% - 54px);
    position: relative;
  }
}
</style>
